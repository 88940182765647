<template>
  <div
    class="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
    style="background-image: url('media/illustrations/progress-hd.png')"
  >
    <!--begin::Content-->
    <div class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
      <!--begin::Logo-->
      <a href="#" class="mb-6">
        <h1 class="logo display-5 text-primary">Bauwise</h1>
      </a>
      <!--end::Logo-->

      <!--begin::Wrapper-->
      <div class="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
        <!--begin::Form-->
        <Form
          class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
          @submit="onSubmitResetPassword"
          id="kt_login_password_reset_form"
          :validation-schema="resetPassword"
        >
          <!--begin::Heading-->
          <div class="text-center mb-10">
            <!--begin::Title-->
            <h1 class="text-dark mb-3">Change Password</h1>
            <!--end::Title-->

            <!--begin::Link-->
            <div class="text-gray-400 fw-bold fs-4">Enter your new password.</div>
            <!--end::Link-->
          </div>
          <!--begin::Heading-->

          <!--begin::Input group-->
          <div class="mb-10 fv-row" data-kt-password-meter="true">
            <!--begin::Wrapper-->
            <div class="mb-1">
              <!--begin::Label-->
              <div class="d-flex justify-content-between align-items-end">
                <label class="form-label fw-bolder text-dark fs-6" for="newPassword">Password</label>

                <button
                  type="button"
                  class="btn btn-color-primary"
                  id="kt_test"
                  @click="showPassword = !showPassword"
                >
                  <i class="far fs-2x" :class="showPassword ? 'fa-eye-slash' : 'fa-eye'"></i>
                  {{ showPassword ? `Hide` : `Show` }}
                </button>
              </div>

              <!--end::Label-->

              <!--begin::Input wrapper-->
              <div class="position-relative mb-3">
                <Field
                  id="newPassword"
                  class="form-control form-control-lg form-control-solid"
                  :type="showPassword ? 'text' : 'password'"
                  name="password"
                  autocomplete="new-password"
                  tabindex="1"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="password" />
                  </div>
                </div>
                <label class="form-label text-dark fs-7">
                  Use 6 or more characters with a mix of letters, numbers &#38;
                  symbols
                </label>
              </div>
              <!--end::Input wrapper-->
            </div>
            <!--end::Wrapper-->
          </div>
          <!--end::Input group-->

          <p>
            This site is protected by reCAPTCHA and the Google
            <a
              href="https://policies.google.com/privacy"
            >Privacy Policy</a> and
            <a href="https://policies.google.com/terms">Terms of Service</a> apply.
          </p>

          <!--begin::Actions-->
          <div class="d-flex flex-wrap justify-content-center pb-lg-0">
            <button
              type="submit"
              ref="submitButton"
              id="kt_password_reset_submit"
              class="btn btn-lg btn-primary fw-bolder me-4"
            >
              <span class="indicator-label">Submit</span>
              <span class="indicator-progress">
                Please wait...
                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>

            <router-link to="/sign-up" class="btn btn-lg btn-light-primary fw-bolder">Cancel</router-link>
          </div>
          <!--end::Actions-->
        </Form>
        <!--end::Form-->
        <div class="d-flex align-items-center p-10 pb-0">
          <Multiselect
            class="m-0 me-5 custom__multiselect"
            tabindex="6"
            v-bind="languageDropdown"
            v-model="languageDropdown.value"
            @change="handleLocaleChanges"
          >
            <template v-slot:caret>
              <div class="multiselect-caret custom__caret"></div>
            </template>
            <template v-slot:singlelabel="{ value }">
              <div class="multiselect-single-label">
                <img class="rounded-1 multiselect-label-icon" :src="value.icon" />
                {{ value.label }}
              </div>
            </template>

            <template v-slot:option="{ option }">
              <img class="rounded-1 multiselect-option-icon" :src="option.icon" />
              {{ option.label }}
            </template>
          </Multiselect>
        </div>
      </div>
      <!--end::Wrapper-->
    </div>
    <!--end::Content-->

    <!--begin::Footer-->
    <div class="d-flex flex-center flex-column-auto p-10">
      <!--begin::Links-->
      <div class="d-flex align-items-center fw-bold fs-6">
        <a href="#" class="text-muted text-hover-primary px-2">About</a>

        <a href="#" class="text-muted text-hover-primary px-2">Contact</a>

        <a href="#" class="text-muted text-hover-primary px-2">Contact Us</a>
      </div>
      <!--end::Links-->
    </div>
    <!--end::Footer-->
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, reactive } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import * as Yup from "yup";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useI18n } from "vue-i18n";
import { translate } from "@/core/helpers/config";
import Multiselect from '@vueform/multiselect';
import { useReCaptcha } from 'vue-recaptcha-v3'

enum Language {
  en = 'en',
  et = 'ee',
  au = 'au',
  de = 'de',
  ru = 'ru',
  lt = 'lt',
  uk = 'uk'
}

export default defineComponent({
  name: "password-reset",
  props: ['token'],
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup(props) {
    const i18n = useI18n();
    const store = useStore();
    const router = useRouter();
    const showPassword = ref<boolean>(false);

    const submitButton = ref<HTMLElement | null>(null);
    const reCaptcha = useReCaptcha();
    const languageDropdown = reactive({
      mode: "single",
      caret: true,
      closeOnSelect: true,
      canDeselect: false,
      canClear: false,
      object: false,
      value: 'ee',
      options: [
        {
          value: 'en',
          label: "English",
          icon: "media/flags/united-states.svg"
        },
        {
          value: 'ee',
          label: "Eesti",
          icon: "media/flags/estonia.svg"
        },
        {
          value: 'de',
          label: "Deutsch",
          icon: "media/flags/germany.svg"
        },
        {
          value: 'au',
          label: "Australia",
          icon: "media/flags/australia.svg"
        },
        {
          value: 'lt',
          label: "Lietuvių",
          icon: "media/flags/lithuania.svg"
        },
        {
          value: 'ru',
          label: "Pусский",
          icon: "media/flags/russia.svg"

        },
        {
          value: 'uk',
          label: "Yкраїнська",
          icon: "media/flags/ukraine.svg"
        },
      ],
      trackBy: "value",
      label: "label",
    });

    const recaptcha = async () => {
      await reCaptcha!.recaptchaLoaded();

      const token = await reCaptcha!.executeRecaptcha('submit');

      const userVerified = await store.dispatch(Actions.RECAPTCHA_VERIFY, token);
      return userVerified
    }

    //Create form validation object
    const resetPassword = Yup.object().shape({
      password: Yup.string().min(6).required().label("Password"),
    });

    //Form submit function
    const onSubmitResetPassword = async (values) => {
      // const { data } = await recaptcha()

      // if (!data.success || data.score <= 0.5) return;

      // Activate loading indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      // dummy delay

      const payload = {
        ...values,
        token: props.token
      }

      // Send reset password request
      store
        .dispatch(Actions.RESET_PASSWORD, payload)
        .then(() => {
          Swal.fire({
            text: "Password successfully reset.",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          }).then(function (data) {
            // Go to page after successfully login
            router.replace({ name: "dashboard" });

          });
        })
        .catch(() => {
          // Alert then login failed
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });

      submitButton.value?.removeAttribute("data-kt-indicator");

    };

    const handleLocaleChanges = (value) => {
      i18n.locale.value = value
      localStorage.setItem('lang', value)
    }

    onMounted(() => {
      let language;

      if (localStorage.getItem('lang') === 'undefined' || !localStorage.getItem('lang')) {
        language = window.navigator['userLanguage'] || window.navigator.language;
      } else {
        language = localStorage.getItem('lang')
      }

      const locale = language.toLowerCase();
      languageDropdown.value = locale === 'en-au' ? 'au' : Language[locale.split("-")[0]] || 'ee'
      handleLocaleChanges(languageDropdown.value)
    })

    return {
      onSubmitResetPassword,
      showPassword,
      resetPassword,
      submitButton,
      translate,
      languageDropdown,
      handleLocaleChanges,
    };
  },
});
</script>

<style lang="scss">
@import "../../assets/sass/style";
</style>

<style>
.multiselect-option {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}
</style>